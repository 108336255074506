import { useEffect } from 'react'
import type { RouteComponentProps } from '@reach/router'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

function Logout(_: RouteComponentProps) {
  useEffect(() => {
    sessionStorage.removeItem('user-decathlon')
    window.location.href = 'https://secure.decathlon.com.br/member/logout'
  }, [])

  return (
    <>
      <GatsbySeo noindex nofollow />
    </>
  )
}

export default Logout
